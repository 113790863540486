<template>
  <div class="reg">
    <div class="reg-wrap">
      <div class="title">{{ $t("page.desc") }}</div>
      <div class="name">{{ $t("page.desc1") }}</div>
      <div class="inp">
        <input type="text" :placeholder="$t('page.desc2')" v-model="code" />
      </div>
      <div class="btn" @click="onBtn">{{ $t("page.desc3") }}</div>
    </div>
  </div>
</template>
<script>
import initConcat from "@/utils/initConcat";
import store from "@/store";
export default {
  data() {
    return {
      titleSize: this.$t("invite.doc1"),
      code: "",
    };
  },
  mounted() {
    let codeStr = this.$store.state.inviteCode || this.$route.query.inviteCode;
    if (codeStr) {
      this.code = codeStr;
      store.commit("SET_INVITECODE", codeStr);
    }
  },
  methods: {
    onBtn() {
      let that = this;
      if (this.code == "") {
        this.$message.error(this.$t("page.desc2"));
        return;
      }
      initConcat._loginSignKey(function (key) {
        initConcat._getSignature(key, function (sign) {
          that
            .$post(that.URL.home.userAuth, {
              address: that.$store.state.address,
              chainType: that.$route.query.id,
              walletSign: sign,
              inviteCode: that.code,
            })
            .then((res) => {
              if (res.code == 0) {
                that.$store.commit("SET_TOKEN", res.data.token);
                localStorage.setItem("token", res.data.token);
                that.$post(that.URL.member.info, {}).then((ress) => {
                  if (res.code == 0) {
                    that.$store.commit("SET_USERDATA", ress.data);
                    that.$store.commit("SET_LOGIN", true);
                    this.$store.commit("SET_USERINFO", ress.data);
                    that.$router.go(-1);
                  } else {
                    that.$message.error(res.message);
                  }
                });
              } else {
                that.$message.error(res.message);
              }
            });
        });
      });
    },

    jump_link(url) {
      this.$router.replace({
        path: url,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.reg {
  padding: 116px 0 69px;
  width: 100%;
  min-height: 100vh;
  background: #000;
  .reg-wrap {
    margin: 0 auto;
    width: 480px;
    background: rgba(255, 226, 127, 0.3);
    padding: 40px;
    .title {
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .name {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #a8a8a8;
      margin-bottom: 14px;
    }
    .inp {
      margin-bottom: 40px;
      input {
        padding: 0 24px;
        width: 100%;
        height: 64px;
        background: rgba(255, 226, 127, 0.3);
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        &::placeholder {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #999999;
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 63px;
      font-family: "Nebula";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      background: url("../public/images/asset/swap-query-bt.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
@media (max-width: 750px) {
  .reg {
    min-width: 100%;
    padding: 67px 0 0;
    .reg-wrap {
      margin: 0 auto;
      width: 100%;
      background: #000;
      padding: 24px 16px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
      }
      .name {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #aaaaaa;
      }
      .inp {
        input {
          padding: 0 17px;
          height: 44px;
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }
      .btn {
        width: 343px;
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
